<template>
  <div class="table-container">
    <div class="table-wrapper" @scroll="handleScroll">
      <table
        :class="{
          table: true,
          'first-column-titles': firstColumnTitles,
        }"
      >
        <thead>
          <tr>
            <slot name="checkAll"></slot>
            <th
              v-for="(value, key) in columns"
              :key="key"
              :ref="`column-${key}`"
              class="column"
              :class="{ scrolled }"
            >
              <div class="d-flex align-items-center">
                {{ typeof value === "string" ? value : value.title }}
                <span
                  v-if="sortable"
                  class="sort"
                  :class="getSortClass(key)"
                  @click="onSortClick(key)"
                ></span>
                <span
                  v-if="getFilterType(value)"
                  class="filter-filled"
                  :class="getFilterClass(key)"
                  @click="onFilterClick(key, value)"
                ></span>
                <portal v-if="getFilterType(value) === 'number'" to="overlay">
                  <number-filter-dropdown
                    dropdown-class="filter-dropdown"
                    :open="activeFilterKey === key"
                    :style="{ left: getColumnOffset(key) }"
                    @change="applyActiveFilter"
                    @cancel="resetActiveFilter"
                  ></number-filter-dropdown>
                </portal>
                <portal v-if="getFilterType(value) === 'enum'" to="overlay">
                  <multi-select-dropdown
                    v-if="getFilterType(value) === 'enum'"
                    dropdown-class="filter-dropdown"
                    :open="activeFilterKey === key"
                    :items="
                      Object.keys(value.filter.values).map((key) => ({
                        value: key,
                        label: value.filter.values[key],
                      }))
                    "
                    :initial-checked="Object.keys(value.filter.values)"
                    :style="{ left: getColumnOffset(key) }"
                    @change="applyActiveFilter"
                    @cancel="resetActiveFilter"
                  ></multi-select-dropdown>
                </portal>
                <portal v-if="getFilterType(value) === 'async'" to="overlay">
                  <async-multi-select-dropdown
                    v-if="getFilterType(value) === 'async'"
                    dropdown-class="filter-dropdown"
                    :open="activeFilterKey === key"
                    :async-items="value.filter.values"
                    :style="{ left: getColumnOffset(key) }"
                    @change="applyActiveFilter"
                    @cancel="resetActiveFilter"
                  ></async-multi-select-dropdown>
                </portal>
                <portal v-if="getFilterType(value) === 'date'" to="overlay">
                  <period-filter-modal
                    v-if="getFilterType(value) === 'date'"
                    class="filter-dropdown"
                    default-view-type="days"
                    :open="activeFilterKey === key"
                    :period="periods[key]"
                    :style="{ left: '50%', transform: 'translateX(-50%)' }"
                    :filter="true"
                    @change="onPeriodChange"
                    @cancel="resetActiveFilter"
                  ></period-filter-modal>
                </portal>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="keyExtractor(item)">
            <slot name="checkItem" :item="item"></slot>
            <td
              v-for="(_, key) in columns"
              :key="key"
              class="column"
              :class="{ scrolled }"
              :style="getColumnStyle(key)"
            >
              <slot :name="key" :item="item">
                <div v-if="!!getValue(item, key) && typeof getValue(item, key) === 'object'">
                  <div v-if="getValue(item, key).type == 'icon'">
                    <font-awesome-icon
                      :icon="getValue(item, key).icon"
                      :class="getValue(item, key).class"
                    />
                  </div>
                  <div v-if="getValue(item, key).type == 'header'" class="table-header">
                    {{ getValue(item, key).value }}
                  </div>
                  <div v-else>
                    {{ getValue(item, key).value }}
                  </div>
                </div>
                <div v-else>
                  {{ getValue(item, key) }}
                </div>
              </slot>
            </td>
          </tr>
          <tr v-if="loading">
            <td :colspan="Object.keys(columns).length" class="text-center">
              <AppSpinner />
            </td>
          </tr>
          <tr v-if="!loading && data.length == 0">
            <td :colspan="Object.keys(columns).length" class="text-center">
              <slot name="empty"></slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <portal-target class="overlay" name="overlay" multiple></portal-target>
  </div>
</template>
<script>
import Vue from "vue";
import get from "lodash/get";
import omit from "lodash/omit";
import moment from "moment";
import NumberFilterDropdown from "@/components/Dropdowns/NumberFilterDropdown.vue";
import MultiSelectDropdown from "@/components/Dropdowns/MultiSelectDropdown.vue";
import AsyncMultiSelectDropdown from "@/components/Dropdowns/AsyncMultiSelectDropdown.vue";
import PeriodFilterModal from "@/components/Lists/PeriodFilter/PeriodFilterModal.vue";

export default {
  name: "Table",
  components: {
    NumberFilterDropdown,
    MultiSelectDropdown,
    AsyncMultiSelectDropdown,
    PeriodFilterModal,
  },
  props: {
    loading: Boolean,
    sortable: Boolean,
    columns: Object,
    data: Array,
    keyExtractor: Function,
    columnStyles: Object,
    firstColumnTitles: Boolean,
    sort: Object,
  },
  data() {
    return {
      scrollOffset: 0,
      activeFilter: null,
      periods: this.getColumnPeriods(Object.keys(this.columns)),
    };
  },
  computed: {
    scrolled() {
      return this.scrollOffset > 0;
    },
    activeFilterKey() {
      return this.activeFilter?.key;
    },
  },
  watch: {
    scrollOffset(current, previous) {
      if (current !== previous) {
        this.resetActiveFilter();
      }
    },
    columns(current, previous) {
      const periodsToRemove = Object.keys(previous).filter(
        (previousColumn) => !Object.keys(current).find((column) => column === previousColumn),
      );

      const periodsToAdd = Object.keys(current).filter(
        (column) => !Object.keys(previous).find((previousColumn) => column === previousColumn),
      );

      this.periods = {
        ...omit(this.periods, periodsToRemove),
        ...this.getColumnPeriods(periodsToAdd),
      };
    },
  },
  methods: {
    getColumnPeriods(columns) {
      return columns.reduce((result, current) => {
        if (this.getFilterType(this.columns[current]) !== "date") {
          return result;
        }

        return {
          ...result,
          [current]: { startDate: new Date(), endDate: new Date() },
        };
      }, {});
    },
    getValue(item, key) {
      const obj = get(item, key);
      let value = obj;

      if (typeof value === "object") {
        value = value?.value;
      }

      if (
        !!value &&
        (moment(value, "DD-MM-YYYY", true).isValid() ||
          moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid())
      ) {
        const mdate = moment(value, "DD-MM-YYYY", true).isValid()
          ? moment(value, "DD-MM-YYYY")
          : moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ");

        if (typeof obj === "object") {
          return {
            ...obj,
            value: mdate.format("DD-MM-YYYY"),
          };
        }
        return mdate.format("DD-MM-YYYY");
      }
      if (this.isNumeric(value)) {
        if (value.toString().split(".")[1] && value.toString().split(".")[1].length > 2) {
          if (typeof obj === "object") {
            return {
              ...obj,
              value: parseFloat(value).toFixed(2),
            };
          }
          return parseFloat(value).toFixed(2);
        }
        if (
          value.toString().split(".")[1] &&
          value.toString().split(".")[1].length == 1 &&
          value.toString().split(".")[1] == 0
        ) {
          if (typeof obj === "object") {
            return {
              ...obj,
              value: parseInt(value),
            };
          }
          return parseInt(value);
        }
      }
      return get(item, key);
    },
    isNumeric(str) {
      if (typeof str !== "string") return false;
      return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
    },
    handleScroll(event) {
      this.scrollOffset = event.target.scrollLeft;
    },
    getColumnStyle(column) {
      if (this.columnStyles) {
        return this.columnStyles[column];
      }

      return undefined;
    },
    getColumnOffset(column) {
      const ref = this.$refs[`column-${column}`]?.[0];
      const offset = ref?.offsetLeft || 0;
      return `${offset - this.scrollOffset}px`;
    },
    getSortClass(key) {
      return {
        active: this.sort?.key === key,
        reverse: this.sort?.key === key && this.sort?.order === -1,
      };
    },
    onSortClick(key) {
      if (this.sort?.key === key) {
        this.$emit("sort", { key, order: -this.sort.order });
      } else {
        this.$emit("sort", { key, order: 1 });
      }
    },
    getFilterType(value) {
      if (typeof value === "object" && value.filter) {
        return value.filter.type;
      }

      return undefined;
    },
    getFilterClass(key) {
      return {
        active: this.activeFilterKey === key,
      };
    },
    onFilterClick(key, value) {
      if (this.activeFilter !== key) {
        this.activeFilter = { key, type: this.getFilterType(value) };
      }
    },
    applyActiveFilter(value) {
      this.$emit("filterChange", {
        [this.activeFilterKey]: { value, type: this.activeFilter.type },
      });
      this.activeFilter = null;
    },
    resetActiveFilter() {
      this.activeFilter = null;
    },
    onPeriodChange(value) {
      Vue.set(this.periods, this.activeFilterKey, value);
      this.applyActiveFilter(value);
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/bootstrap/custom-bootstrap.scss";

.first-column-titles {
  tbody > tr > td:first-child {
    font-weight: 500;
    color: #0e0649;
  }
}

.table-container {
  position: relative;

  .table-wrapper {
    overflow-x: auto;
    border-radius: $border-radius;
    box-shadow: 0 0 0 1px $gray;
    margin-bottom: 1rem;

    .table {
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 0;

      .table-header {
        color: #949494;
      }

      th {
        white-space: nowrap;
      }

      .sort,
      .filter-filled {
        margin-left: 0.5rem;
        opacity: 0.2;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.5;
        }

        &.active {
          opacity: 1;
        }
      }

      .sort {
        transform: scaleY(-1);

        &.reverse {
          transform: scaleY(1);
        }
      }

      .column:first-child {
        z-index: 2;
        position: sticky;
        left: 0;

        &.scrolled {
          background-color: $white;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            border-right: 3px solid $gray;
          }
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .filter-dropdown {
      pointer-events: auto;
      top: 2.5em;
      left: 0;
      right: auto;
    }
  }
}
</style>
