<template>
  <Table :columns="columns" :data="exports" :key-extractor="keyExtractor">
    <template #documents="{ item: im }">
      <a
        v-if="im.attributes.transport_document"
        class="download"
        target="_blank"
        @click="onTransportDocumentClick(im)"
      >
        Transport
      </a>
      &nbsp;&nbsp;
      <a
        v-if="im.attributes.transport_document"
        class="download"
        target="_blank"
        style="margin-left: 20px"
        @click="onVkiDocumentClick(im)"
      >
        VKI
      </a>
    </template>
    <template #empty>
      <template v-if="exports.length === 0">Er zijn nog geen exports gemaakt</template>
      <template v-else>Geen exports gevonden</template>
    </template>
  </Table>
</template>
<script>
export default {
  name: "ExportsTable",
  data() {
    return {
      exports: [],
      error: "",
      query: "",
      columns: {
        "attributes.scheduled": "Datum",
        "attributes.carrier_name": "Transporteur",
        "attributes.destination_name": "Ontvanger",
        documents: "Documenten",
      },
    };
  },
  created() {
    this.$http.secured
      .get("/exports")
      .then((response) => {
        this.exports = response.data.data;
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    onTransportDocumentClick(ex) {
      this.$http.secured
        .get(`/exports/${ex.id}/transport_document`, {
          responseType: "blob",
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "transport_document.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    onVkiDocumentClick(ex) {
      this.$http.secured
        .get(`/exports/${ex.id}/vki_document`, {
          responseType: "blob",
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "vki_document.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    keyExtractor(im) {
      return im.attributes.id;
    },
  },
};
</script>
<style></style>
