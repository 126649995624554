var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pageLoader_isDataLoaded)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title"},[_vm._v(" Sponzen "),(_vm.animals)?_c('small',[_vm._v(" ("+_vm._s(_vm.pagination.totalCount)+") ")]):_vm._e()])])]),(_vm.statistics)?_c('div',{staticClass:"row"},[_c('core-stat-card',{attrs:{"category":"Totaal ingebrachte sponzen","value":_vm.statistics.number_of_inserted_sponges}}),_c('core-stat-card',{attrs:{"category":"Totaal uitgenomen sponzen","value":_vm.statistics.number_of_extracted_sponges}})],1):_vm._e(),_c('div',{staticClass:"mt-4 d-flex justify-content-end"},[_c('period-filter',{attrs:{"period":_vm.period},on:{"change":_vm.handlePeriodChange}}),_c('export-options',{attrs:{"period":_vm.period,"url":'default_lists/sponges/all'}})],1),(_vm.animals)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('Table',{attrs:{"sortable":"","columns":_vm.columns,"data":_vm.animals,"key-extractor":_vm.keyExtractor,"sort":_vm.sort},on:{"sort":_vm.onSort},scopedSlots:_vm._u([{key:"animal_life_number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"animal_numbers",attrs:{"to":{
                    path: '/passport/' + item.animal.id + '/rvo',
                  }}},[_c('span',[_vm._v(_vm._s(item.animal.animal_numbers.life_number))]),_c('span',[_vm._v(_vm._s(item.animal.animal_numbers.work_number))])])]}},{key:"animal_type",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.animal.human_animal_type)+" ")]}},{key:"date_of_birth",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.animal.date_of_birth)+" ")]}},{key:"inserted_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.inserted_at))+" ")]}},{key:"extracted_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.extracted_at))+" ")]}},{key:"empty",fn:function(){return [(_vm.animals.length === 0)?[_vm._v("Geen dieren")]:[_vm._v("Geen dieren gevonden")]]},proxy:true}],null,false,1383442207)}),(_vm.pagination.totalPages > 1)?_c('paginate',{attrs:{"page-count":_vm.pagination.totalPages,"click-handler":_vm.fetchPaginatedItems,"prev-text":'Vorige',"next-text":'Volgende',"container-class":'pagination justify-content-center pt-3',"page-class":'page-item',"page-link-class":'page-link',"prev-class":'page-item prev-item',"prev-link-class":'page-link prev-link-item',"next-class":'page-item next-item',"next-link-class":'page-link next-link-item',"break-view-class":'break-view',"break-view-link-class":'break-view-link',"first-last-button":false},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)])]):_vm._e()])]):_c('div',{staticClass:"content"},[_c('AppSpinner')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }