<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Melkgift per dier</h1>
          </div>
        </div>
        <div v-if="statistics" class="row">
          <core-stat-card
            category="Gem. melkgift in L"
            :value="statistics.average_milk_yield | roundDecimal"
          />
          <core-stat-card category="Gem. vet in %" :value="statistics.average_fat | roundDecimal" />
          <core-stat-card
            category="Gem. eiwit in %"
            :value="statistics.average_protein | roundDecimal"
          />
          <core-stat-card
            category="Gem. celgetal"
            :value="statistics.average_cells | roundDecimal"
          />
        </div>
        <div class="mt-4 d-flex justify-content-end"></div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex align-items-center">
          <search-input class="mr-auto" column="animal_life_number" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options :period="period" :url="'milk_yields'"></export-options>
        </div>
        <div v-if="milkyields">
          <div class="row">
            <div class="col-12">
              <Table :columns="columns" :data="milkyields" :key-extractor="keyExtractor">
                <template #life_number="{ item: milkyield }">
                  <router-link
                    :to="{
                      path: '/passport/' + milkyield.animal.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ milkyield.animal.attributes.animal_numbers.lifeNumber }}</span>
                    <span>{{ milkyield.animal.attributes.animal_numbers.workNumber }}</span>
                  </router-link>
                </template>

                <template #empty>Geen melkgiften gevonden</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
        <div v-else>
          <AppSpinner />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import SearchInput from "@/components/SearchInput.vue";
import CoreStatCard from "@/components/Cards/CoreStatCard.vue";
import parseTranslations from "@/mixins/parseTranslations";

export default {
  name: "MilkPerAnimal",
  components: {
    PeriodFilter,
    ExportOptions,
    CoreStatCard,
    SearchInput,
  },
  mixins: [parseTranslations, pageLoader, defaultListPage("milk_yields", "milk_yield", true, true)],
  data() {
    return {
      // pagination: null,
      // statistics: null,
      // milkyields: null,
      columns: {
        life_number: "(Werk)nummer",
        "attributes.date": { title: "Datum", filter: { type: "date" } },
        "attributes.amount": "Liters",
        "milker.attributes.company_name": "Melker",
        "attributes.fat": "Vet",
        "attributes.protein": "Eiwit",
        "attributes.lactose": "Lactose",
        "attributes.solids": "Solids",
        "attributes.npn_cu_a2": "NPN/CU a2",
        "attributes.cells": "Cells",
        "attributes.snf": "SNF",
        "attributes.npn_cu": "NPN/CU",
        "attributes.denovo_fatty_acids": "Denovo Fatty Acids",
        "attributes.mixed_fatty_acids": "Mixed Fatty Acids",
        "attributes.preformed_fatty_acids": "Preformed Fatty Acids",
        "attributes.denovo_relative": "Denovo relative",
        "attributes.mixed_relative": "Mixed relative",
        "attributes.preformed_relative": "Preformed relative",
        "attributes.nefa": "NEFA",
      },
    };
  },
  computed: {
    ...mapState({
      milkyields: (state) => {
        return state.default_lists.items.map((milkyield) => {
          const animal = state.default_lists.included.find(
            (animal) => animal.id === milkyield.relationships.animal.data.id,
          );
          const milker = state.default_lists.included.find(
            (milker) => milker.id === milkyield.relationships.milker.data?.id,
          );
          return {
            ...milkyield,
            animal: {
              ...animal,
              attributes: {
                ...animal.attributes,
                animal_numbers: {
                  lifeNumber: animal.attributes.life_number?.substring(
                    0,
                    animal.attributes.life_number.length - 5,
                  ),
                  workNumber: animal.attributes.work_number?.substring(
                    animal.attributes.work_number.length - 5,
                  ),
                },
              },
            },
            milker: {
              ...milker,
              attributes: {
                ...milker?.attributes,
                company_name: milker?.attributes?.company_name,
              },
            },
          };
        });
      },
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(item) {
      return item.id;
    },
  },
};
</script>
<style></style>
