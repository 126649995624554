<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Gestorven dieren</h1>
          </div>
        </div>
        <div v-if="statistics" class="row">
          <core-stat-card category="Totaal" :value="statistics.number_of_deaths" />
          <core-stat-card
            category="Jonger dan 7 dagen"
            :value="statistics.number_of_deaths_below_7_days"
          />
          <core-stat-card
            category="Jonger dan 4 maanden"
            :value="statistics.number_of_deaths_below_4_months"
          />
          <core-stat-card
            category="Vrouwelijke dieren"
            :value="statistics.number_of_female_deaths"
          />
          <core-stat-card category="Mannelijke dieren" :value="statistics.number_of_male_deaths" />
          <core-stat-card
            category="Meest voorkomende oorzaak"
            :value="statistics.most_occuring_death_reason"
          />
        </div>
        <div class="mt-4 mb-2 d-flex align-items-center">
          <search-input class="mr-auto" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options :period="period" :url="'default_lists/deaths/animals'"></export-options>
        </div>
        <div v-if="animals">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="animals"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ animal.animal_numbers.lifeNumber }}</span>
                    <span>{{ animal.animal_numbers.workNumber }}</span>
                  </router-link>
                </template>
                <template #gender="{ item: animal }">
                  {{ animal.human_gender }}
                </template>
                <template #animal_type="{ item: animal }">
                  {{ animal.human_animal_type }}
                </template>
                <template #empty>Geen sterfte</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import CoreStatCard from "@/components/Cards/CoreStatCard.vue";
import SearchInput from "@/components/SearchInput.vue";
import genders from "@/enums/genders";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "Death",
  components: {
    PeriodFilter,
    ExportOptions,
    CoreStatCard,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("deaths/animals", "death")],
  data() {
    return {
      columns: {
        life_number: "(Werk)nummer",
        animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        gender: { title: "Geslacht", filter: { type: "enum", values: genders } },
        date_of_death: { title: "Overleden", filter: { type: "date" } },
        reason_of_death: {
          title: "Oorzaak",
          filter: {
            type: "async",
            values: this.fetchItems({ url: "deaths/death_reasons", filter: {} }).then((result) =>
              result.map((item) => ({
                value: encodeURIComponent(item.reason_of_death),
                label: item.reason_of_death,
              })),
            ),
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      animals: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  methods: {
    keyExtractor(animal) {
      return animal.id;
    },
  },
};
</script>
<style></style>
