<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div v-if="animalId">
        <div class="row justify-content-center my-4">
          <div class="col-4">
            <h6>Levensnummer</h6>
            <span class="font-xxl">{{ animal.life_number }}</span>
          </div>
          <div class="col-4">
            <h6>Diersoort</h6>
            <span class="font-xxl">{{ animal.human_animal_type }}</span>
          </div>
          <div class="col-4">
            <h6>Geboren</h6>
            <span class="font-xxl">
              {{ animal.date_of_birth ? animal.date_of_birth : "—" }}
            </span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-4">
            <h6>Werknummer</h6>
            <span class="font-xxl">{{ animal.life_number.slice(-5) }}</span>
          </div>
          <div class="col-4">
            <h6>Geslacht</h6>
            <span class="font-xxl">{{ animal.human_gender }}</span>
          </div>
          <div class="col-4">
            <h6>Leeftijd</h6>
            <span class="font-xxl">
              {{ animal.date_of_birth ? getYearsAndMonthsOld(animal.date_of_birth) : "—" }}
            </span>
          </div>
        </div>

        <div class="row justify-content-center my-4">
          <div class="col-4">
            <card>
              <div slot="header">Groep</div>
              <router-link v-if="animal.group_id" :to="'/groups/' + animal.group_id">{{
                animal.group_name
              }}</router-link>
              <div v-else>—</div>
            </card>
          </div>
          <div class="col-4">
            <card>
              <div slot="header">Gewicht</div>
              <slot>{{ animal.last_weight ? animal.last_weight : "—" }}</slot>
            </card>
          </div>
          <div class="col-4">
            <card>
              <div slot="header">Moeder</div>
              <div v-if="animal.mother">
                <div class="d-flex justify-content-between">
                  <div>
                    <router-link :to="'/passport/' + animal.mother.id">
                      {{ animal.mother.life_number }}
                    </router-link>
                    <span
                      v-if="animal.mother.exported_at"
                      v-b-tooltip.hover
                      title="Niet op locatie aanwezig"
                    >
                      <font-awesome-icon icon="map-marker-slash" class="mx-1 text-muted" />
                    </span>
                    <span v-else v-b-tooltip.hover title="Op locatie aanwezig">
                      <font-awesome-icon icon="map-marker-check" class="mx-1 text-success" />
                    </span>
                  </div>
                  <div>
                    <small>{{
                      animal.mother.date_of_birth ? animal.mother.date_of_birth : "—"
                    }}</small>
                  </div>
                </div>
              </div>
              <div v-else>—</div>
            </card>
          </div>
        </div>

        <div class="row justify-content-center my-4">
          <div class="col-4">
            <card>
              <div slot="header">Ras</div>
              <slot>
                <div v-if="animal.breed !== null">{{ animal.breed }}</div>
                <div v-else-if="animal.breed_percentages && animal.breed_percentages.length === 0">
                  -
                </div>
                <div v-else-if="animal.breed_percentages && animal.breed_percentages.length === 1">
                  <span
                    >{{ animal.breed_percentages[0].percentage.toFixed(1) }}%
                    {{ animal.breed_percentages[0].breed }}</span
                  >
                </div>
                <div v-else>
                  <ul
                    v-if="animal.breed_percentages"
                    slot="raw-content"
                    class="list-group list-group-flush"
                  >
                    <li
                      v-for="percentage in animal.breed_percentages"
                      :key="percentage.breed"
                      class="list-group-item"
                    >
                      <span>{{ percentage.percentage.toFixed(1) }}% {{ percentage.breed }}</span>
                    </li>
                  </ul>
                </div>
              </slot>
            </card>
          </div>
          <div class="col-4">
            <card>
              <div slot="header">Kenmerken</div>
              <ul
                v-if="animal.characteristics.length"
                slot="raw-content"
                class="list-group list-group-flush"
              >
                <li
                  v-for="characteristic in animal.characteristics"
                  :key="characteristic"
                  class="list-group-item"
                >
                  {{ characteristic }}
                </li>
              </ul>
              <div v-else>—</div>
            </card>
          </div>
          <div class="col-4">
            <card>
              <div slot="header">Vader (mogelijk)</div>
              <ul
                v-if="animal.fathers.length > 0"
                slot="raw-content"
                class="list-group list-group-flush"
              >
                <li
                  v-for="fatherAnimal in animal.fathers"
                  :key="fatherAnimal.id"
                  class="list-group-item"
                >
                  <div class="d-flex justify-content-between">
                    <div>
                      <router-link :to="'/passport/' + fatherAnimal.id">{{
                        fatherAnimal.life_number
                      }}</router-link>
                      <span
                        v-if="fatherAnimal.exported_at"
                        v-b-tooltip.hover
                        title="Niet op locatie aanwezig"
                      >
                        <font-awesome-icon icon="map-marker-slash" class="mx-1 text-muted" />
                      </span>
                      <span v-else v-b-tooltip.hover title="Op locatie aanwezig">
                        <font-awesome-icon icon="map-marker-check" class="mx-1 text-success" />
                      </span>
                    </div>
                    <div>
                      <small>{{
                        fatherAnimal.date_of_birth ? fatherAnimal.date_of_birth : "—"
                      }}</small>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-else>—</div>
            </card>
          </div>
        </div>

        <div class="row justify-content-center my-4">
          <div class="col-8">
            <card>
              <div slot="header">Groeicurve gewicht</div>
              <div>
                <line-chart-container :weighings="animal.weighings"></line-chart-container>
              </div>
            </card>
          </div>
          <div class="col-4 justify-content-center">
            <div>
              <card>
                <div slot="header">Nakomelingen</div>
                <ul
                  v-if="animal.offsprings.length > 0"
                  slot="raw-content"
                  class="list-group list-group-flush"
                >
                  <li
                    v-for="offspring in animal.offsprings"
                    :key="offspring.id"
                    class="list-group-item"
                  >
                    <div v-if="offspring.id">
                      <div class="d-flex justify-content-between">
                        <div>
                          <router-link :to="'/passport/' + offspring.id">{{
                            offspring.life_number
                          }}</router-link>
                          <font-awesome-icon
                            v-if="offspring.human_gender === 'Vrouw'"
                            icon="venus"
                            class="mx-1 text-success"
                          />
                          <font-awesome-icon
                            v-else-if="offspring.human_gender === 'Man'"
                            icon="mars"
                            class="mx-1 text-success"
                          />

                          <span
                            v-if="offspring.exported_at"
                            v-b-tooltip.hover
                            title="Niet op locatie aanwezig"
                          >
                            <font-awesome-icon icon="map-marker-slash" class="mx-1 text-muted" />
                          </span>
                          <span v-else v-b-tooltip.hover title="Op locatie aanwezig">
                            <font-awesome-icon icon="map-marker-check" class="mx-1 text-success" />
                          </span>
                        </div>
                        <div>
                          <small>{{
                            offspring.date_of_birth ? offspring.date_of_birth : "—"
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div v-else>—</div>
                  </li>
                </ul>

                <div v-else>—</div>
              </card>
            </div>
            <div class="my-4">
              <card>
                <div slot="header">Verwachte lamdatum</div>
                <div v-if="animal.lambing_date">
                  {{ animal.lambing_date }}
                </div>
                <div v-else>Onbekend</div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import LineChartContainer from "@/components/ChartContainer";
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "Overview",
  components: {
    LineChartContainer,
  },
  mixins: [pageLoader],
  data() {
    return {
      animalLoaded: false,
      familyTreeLoaded: false,
      animals: [],
      animalId: "",
      offsprings: [],
      mother: {},
    };
  },
  computed: {
    ...mapState({
      lifeNumbers: (state) => state.animals.lifeNumbers,
      animal: (state) => state.animals.item,
    }),
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.animalId = this.$route.params.id;
      Promise.all([this.fetchAnimalById(this.animalId)])
        .then(() => this.pageLoader_resolveData())
        .catch(() => {
          this.pageLoader_resolveData();
        });
    }
  },

  methods: {
    ...mapActions("animals", ["fetchAnimalLifeNumbers", "fetchAnimalById"]),
    getYearsAndMonthsOld(date) {
      const dateArray = date?.split("-")?.reverse();

      if (!this.validAnimalDateArray(dateArray)) {
        return "";
      }

      const dateNow = moment();
      const birthDate = moment(date, "DD-MM-YYYY");

      const years = dateNow.diff(birthDate, "year");
      birthDate.add(years, "years");
      const months = dateNow.diff(birthDate, "months");
      const monthsText = months === 1 ? "maand" : "maanden";

      return `${years === 0 ? "" : `${years} jaar `}${months} ${monthsText} `;
    },
    validAnimalDateArray(dateArray) {
      if (dateArray && parseInt(dateArray[0]) && parseInt(dateArray[1]) && parseInt(dateArray[2])) {
        return true;
      }

      return false;
    },
    getDaysOld(date) {
      const dateArray = date?.split("-")?.reverse();

      if (!this.validAnimalDateArray(dateArray)) {
        return "";
      }

      const dateNow = moment();
      const birthDate = moment(dateArray);
      const days = dateNow.diff(birthDate, "days");
      // const daysText = days === 1 ? "dag" : "dagen";

      return days;
    },
  },
};
</script>
<style></style>
