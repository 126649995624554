<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div v-if="animalDoses">
        <div class="row">
          <div class="col-12">
            <Table :columns="columns" :data="animalDoses" :key-extractor="keyExtractor">
              <template #amount="{ item: dose }">{{ dose.amount }} {{ dose.unit }}</template>
              <template #empty>Geen giften</template>
            </Table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import moment from "moment";

import { mapActions, mapState } from "vuex";
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "MedicineHistory",
  mixins: [pageLoader],

  data() {
    return {
      animalLoaded: false,
      animalId: "",
      columns: {
        dose_date: "Datum",
        medicine_name: "Medicijn",
        amount: "Hoeveelheid",
      },
    };
  },
  computed: {
    ...mapState({
      animal: (state) => state.animals.item,
      doses: (state) => state.doses.items,
    }),
    motherAnimal() {
      return this.lifeNumbers.find((lifeNumber) => lifeNumber.id === this.animal.mother_id || {});
    },
    fatherAnimals() {
      return this.lifeNumbers.filter((lifeNumber) =>
        this.animal.father_ids.includes(lifeNumber.id),
      );
    },
    animalDoses() {
      return this.doses.filter((dose) => dose.animal_id === this.animal.id);
    },
  },
  created() {
    this.animalId = this.$route.params.id;
    Promise.all([
      this.fetchAnimalLifeNumbers(),
      this.fetchAnimalById(this.animalId),
      this.fetchDoses(),
    ])
      .then(() => this.pageLoader_resolveData())
      .catch(() => {
        this.pageLoader_resolveData();
      });
  },
  methods: {
    ...mapActions("animals", ["fetchAnimalLifeNumbers", "fetchAnimalById"]),
    ...mapActions("doses", ["fetchDoses"]),
    getYearsAndMonthsOld(date) {
      const dateArray = date?.split("-")?.reverse();

      if (!this.validAnimalDateArray(dateArray)) {
        return "";
      }

      const dateNow = moment();
      const birthDate = moment(dateArray);

      const years = dateNow.diff(birthDate, "year");
      birthDate.add(years, "years");
      const months = dateNow.diff(birthDate, "months");
      const monthsText = months === 1 ? "maand" : "maanden";

      return `${years === 0 ? "" : `${years} jaar `}${months} ${monthsText} `;
    },
    validAnimalDateArray(dateArray) {
      if (dateArray && parseInt(dateArray[0]) && parseInt(dateArray[1]) && parseInt(dateArray[2])) {
        return true;
      }

      return false;
    },
    getDaysOld(date) {
      const dateArray = date?.split("-")?.reverse();

      if (!this.validAnimalDateArray(dateArray)) {
        return "";
      }

      const dateNow = moment();
      const birthDate = moment(dateArray);
      const days = dateNow.diff(birthDate, "days");
      // const daysText = days === 1 ? "dag" : "dagen";

      return days;
    },
    keyExtractor(dose) {
      return dose.id;
    },
  },
};
</script>
<style></style>
