<template>
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="page-title">Technische kengetallen</h1>
        </div>
      </div>
      <div class="mt-4 mb-2 d-flex align-items-center justify-content-between">
        <base-input
          v-model="searchQuery"
          type="text"
          placeholder="Zoeken..."
          class="form-group search figures-search"
        ></base-input>
        <div class="d-flex justify-content-end align-items-center">
          <period-filter
            :period="period"
            default-view-type="quarters"
            :disable-views="['days']"
            @change="handlePeriodChange"
          >
          </period-filter>
          <export-options :url="'statistics/key_figures'" :period="period"></export-options>
        </div>
      </div>
      <div v-if="filteredStatistics">
        <div class="row">
          <div class="col-12">
            <Table
              :columns="columns"
              :data="filteredStatistics"
              :loading="!pageLoader_isDataLoaded"
              :key-extractor="keyExtractor"
              :first-column-titles="true"
            >
              <template #empty>Geen kengetallen</template>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import pageLoader from "@/mixins/pageLoader";
import ExportOptions from "@/components/Lists/ExportOptions.vue";
import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
import { applyFilters } from "@/helpers";

const FIELDS = [
  {
    type: "header",
    label: "Alle dieren",
  },
  {
    type: "total",
    key: "number_of_female_animals",
    label: "Totaal aantal vrouwelijke dieren",
  },
  {
    type: "total",
    key: "number_of_male_animals",
    label: "Totaal aantal mannelijke dieren",
  },
  {
    type: "total",
    key: "number_of_genderless_animals",
    label: "Totaal aantal onbekende dieren",
  },
  {
    type: "total",
    key: "number_of_old_animals",
    label: "Totaal jonger dan 1 jaar",
  },
  {
    type: "total",
    key: "number_of_young_animals",
    label: "Totaal ouder dan 1 jaar",
  },
  {
    type: "total",
    key: "number_of_animals",
    label: "Totaal aantal dieren",
  },
  {
    type: "header",
    label: "Geboren",
  },
  {
    type: "total",
    key: "number_of_litter",
    label: "Aantal worpen",
  },
  {
    type: "avarage",
    key: "avarage_litter_size",
    label: "Gemiddelde worpgrootte",
  },
  {
    type: "total",
    key: "number_female_lambs",
    label: "Aantaal vrouwelijke lammeren",
  },
  {
    type: "total",
    key: "number_male_lambs",
    label: "Aantal mannelijke lammeren",
  },
  {
    type: "total",
    key: "number_of_lambs_born_alive",
    label: "Aantal levend geboren lammeren",
  },
  {
    type: "total",
    key: "number_of_lambs_born_death",
    label: "Aantal doodgeboren lammeren",
  },
  {
    type: "header",
    label: "Gestorven",
  },
  {
    type: "total",
    key: "number_of_female_deaths",
    label: "Aantal gestorven vrouwelijk",
  },
  {
    type: "total",
    key: "number_of_male_deaths",
    label: "Aantal gestorven mannelijk",
  },
  {
    type: "total",
    key: "number_of_deaths_below_7_days",
    label: "Gestorven jonger dan 7 dagen",
  },
  {
    type: "total",
    key: "number_of_deaths_below_4_months",
    label: "Gestorven jonger dan 4 maanden",
  },
  {
    type: "total",
    key: "number_of_deaths",
    label: "Totaal gestorven aantal dieren",
  },
  {
    type: "most",
    key: "most_occuring_death_reason",
    label: "Meest voorkomende doodsoorzaak",
  },
  {
    type: "header",
    label: "Gewicht",
  },
  {
    type: "avarage",
    key: "growth_since_birth_weighing",
    label: "Gemiddelde groei sinds geboorte",
  },
  {
    type: "avarage",
    key: "growth_since_last_weighing",
    label: "Gemiddelde groei sinds laatste gewicht",
  },
  {
    type: "header",
    label: "Medicijnen",
  },
  {
    type: "avarage",
    key: "average_medicine_doses",
    label: "Gemiddelde toediening per dier",
  },
  {
    type: "avarage",
    key: "average_usage_per_animal",
    label: "Gemiddelde verbruik per dier",
  },
  {
    type: "most",
    key: "most_used_medicine",
    label: "Meest gebruikt medicijn",
  },
];

export default {
  name: "TechnicalFigures",
  components: {
    ExportOptions,
    PeriodFilter,
  },
  mixins: [pageLoader],
  data() {
    const columnDefinitions = {
      description: "",
    };

    return {
      columnDefinitions,
      visibleColumns: Object.keys(columnDefinitions),
      searchQuery: "",
      values: [],
      periodType: "quarters",
      period: {
        startDate: moment().quarter(1).startOf("quarter").toDate(),
        endDate: moment().quarter(4).endOf("quarter").toDate(),
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    filteredStatistics() {
      const map = this.values.filter((category) =>
        typeof category.description === "string"
          ? category.description.toLowerCase()?.includes?.(this.searchQuery.toLowerCase())
          : category.description.value.toLowerCase()?.includes?.(this.searchQuery.toLowerCase()),
      );
      return map;
    },
    columns() {
      return Object.keys(this.columnDefinitions).reduce((result, columnKey) => {
        if (this.visibleColumns.includes(columnKey)) {
          return { ...result, [columnKey]: this.columnDefinitions[columnKey] };
        }

        return result;
      }, {});
    },
  },
  created() {
    this.pageLoader_loadingData();
    this.handleFetchStatistics();
  },
  methods: {
    handleFetchStatistics() {
      this.$http.secured
        .get(
          applyFilters("/statistics/key_figures", {
            start_date: moment(this.period.startDate).format("DD-MM-YYYY"),
            end_date: moment(this.period.endDate).format("DD-MM-YYYY"),
          }),
        )
        .then((res) => {
          let fields = {};
          const data = [];

          // eslint-disable-next-line default-case
          switch (this.periodType) {
            case "months": {
              fields = res.data.reduce(
                (acc, item) => {
                  return {
                    ...acc,
                    [item.date]: moment(item.date).format("MMMM YYYY"),
                  };
                },
                {
                  description: "Omschrijving",
                },
              );

              FIELDS.forEach((field) => {
                const dataForField = {};

                res.data.forEach((item) => {
                  dataForField[item.date] = item[field.key];
                });

                data.push({
                  description: {
                    type: field.type,
                    value: field.label,
                  },
                  ...dataForField,
                });
              });
              break;
            }
            case "quarters": {
              fields = res.data.reduce(
                (acc, item) => {
                  return {
                    ...acc,
                    [`q${moment(item.date).quarter()}-${moment(
                      item.date,
                    ).year()}`]: `Kwartaal ${moment(item.date).quarter()} (${moment(
                      item.date,
                    ).year()})`,
                  };
                },
                {
                  description: "Omschrijving",
                },
              );

              FIELDS.forEach((field) => {
                const dataForField = {};

                res.data.forEach((item) => {
                  if (!dataForField[`q${moment(item.date).quarter()}-${moment(item.date).year()}`])
                    dataForField[`q${moment(item.date).quarter()}-${moment(item.date).year()}`] =
                      [];
                  dataForField[`q${moment(item.date).quarter()}-${moment(item.date).year()}`].push(
                    item[field.key],
                  );
                });

                Object.keys(dataForField).forEach((key) => {
                  dataForField[key] = this.mergeValues(field.type, dataForField[key]);
                });

                data.push({
                  description: {
                    type: field.type,
                    value: field.label,
                  },
                  ...dataForField,
                });
              });
              break;
            }
            case "years": {
              fields = res.data.reduce(
                (acc, item) => {
                  return {
                    ...acc,
                    [`y${moment(item.date).year()}`]: `${moment(item.date).year()}`,
                  };
                },
                {
                  description: "Omschrijving",
                },
              );

              FIELDS.forEach((field) => {
                const dataForField = {};

                res.data.forEach((item) => {
                  if (!dataForField[`y${moment(item.date).year()}`])
                    dataForField[`y${moment(item.date).year()}`] = [];
                  dataForField[`y${moment(item.date).year()}`].push(item[field.key]);
                });

                Object.keys(dataForField).forEach((key) => {
                  dataForField[key] = this.mergeValues(field.type, dataForField[key]);
                });

                data.push({
                  description: {
                    type: field.type,
                    value: field.label,
                  },
                  ...dataForField,
                });
              });
              break;
            }
          }

          this.columnDefinitions = fields;
          this.visibleColumns = Object.keys(fields);
          this.values = data;
        })
        .finally(() => {
          this.pageLoader_resolveData();
        });
    },
    mergeValues(type, values) {
      if (!values?.length) return null;
      switch (type) {
        case "total": {
          return values.reduce(
            (acc, curr) => Number(parseFloat(acc)) + Number(parseFloat(curr)),
            0,
          );
        }
        case "avarage": {
          if (!Number.isNaN(parseFloat(values[0]))) {
            return (
              values.reduce((acc, curr) => Number(parseFloat(acc)) + Number(parseFloat(curr)), 0) /
              values.length
            ).toFixed(2);
          }
          return null;
        }
        case "most":
          return _.head(_(values).countBy().entries().maxBy(_.last));
        default:
          return null;
      }
    },
    setPeriod(period) {
      const { startDate, endDate } = period;
      this.$store.commit("statistics/setPeriod", { startDate, endDate });
    },
    handlePeriodChange(data) {
      this.period = { startDate: data.startDate, endDate: data.endDate };
      this.periodType = data.type;
      this.handleFetchStatistics();
    },
    keyExtractor(statistic) {
      return statistic.description?.value?.toString?.() || statistic.description.toString();
    },
  },
};
</script>
<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: transparent;
  border: none;
  color: #2e994c;
  text-transform: lowercase;
  font-variant: normal;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #2e994c;
}
.style-chooser .vs__selected-options {
  display: inline;
  flex-grow: 0;
  flex-basis: unset;
}
.style-chooser .vs__selected {
  color: #2e994c;
  font-size: 2em;
  padding-left: 0px;
}

.figures-search {
  margin-bottom: 0 !important;
}
</style>
