<template>
  <div class="weighings">

    <div class="addInputs">
      <div v-if="openEdit">

    <period-filter-modal
        arrow-anabled
        :single-date="true"
        :open="openEdit"
        default-view-type="days"
        :period="inputValue.date ? inputValue.date : new Date()"
        @change="handleChangeEdit"
        @cancel="openEdit = false"
      ></period-filter-modal>
      </div>
    </div>


    <div v-for="(weight, index) in weighings" :key="weight" class="itemContainer">
      <div v-if="promptRemove && promptRemoveIndex === index" class="promptRemoveContainer">
        <span>Zeker weten?</span>
        <div class="promptBtnContainer">
          <button class="btn cancel" @click="promptRemove = false">Nee</button>
          <button class="btn btn-success proceed" @click="handleRemove(index)">Ja</button>
        </div>
      </div>

      <div class="inputWrapper">
        <input
          v-model="weighings[index].weight"
          :disabled="selectedOptionType !== 'edit'"
          :class="selectedOptionType !== 'edit' ? 'disabledInput' : 'input'"
          @input="updateWeighing($event, index, 'weight')"
        />
        <span class="icon">kg</span>
      </div>
      <div class="inputWrapper">
        <input
          v-model="weighings[index].date"
          :disabled="selectedOptionType !== 'edit'"
          :class="selectedOptionType !== 'edit' ? 'disabledInput' : 'input'"
          @click="startEditDate(index)"
          @input="updateWeighing($event, index, 'date')"
        />
        <span class="icon">
          <b-icon-calendar2-check class="icon" />
        </span>
      </div>
      <button
        v-if="selectedOptionType === 'remove'"
        class="btn remove"
        @click="handlePromptRemove(index)"
      >
        <b-icon-trash-fill class="removeIcon" />
      </button>
    </div>

    <div v-if="selectedOptionType === 'add' && addInput === true" class="addInputs">
      <period-filter-modal
        arrow-anabled
        :single-date="true"
        :open="open"
        default-view-type="days"
        :period="inputValue.date ? inputValue.date : new Date()"
        @change="handleChange"
        @cancel="open = false"
      ></period-filter-modal>
      <div class="inputWrapper">
        <input
          v-model="inputValue.weight"
          placeholder="kg"
          type="text"
          class="input"
          @keyup.enter="submitInput"
        />
        <span class="icon">kg</span>
      </div>
      <div class="inputWrapper">
        <input
          v-model="inputValue.date"
          class="input"
          placeholder="Selecteer datum"
          @click="open = true"
        />
        <span class="icon">
          <b-icon-calendar2-check class="icon" />
        </span>
      </div>
      <b-button class="btn button" variant="warning" @click="submitInput"
        >Gewicht bevestigen</b-button
      >
    </div>
    <button v-if="selectedOptionType === 'add'" class="btn addInputButton" @click="addInput = true">
      Nieuwe toevoegen<b-icon-plus class="plusIcon" />
    </button>
  </div>
</template>

<script>
import { BIconTrashFill, BIconPlus, BIconCalendar2Check } from "bootstrap-vue";
import moment from "moment";
import PeriodFilterModal from "@/components/Lists/PeriodFilter/PeriodFilterModal.vue";

export default {
  name: "WeighingsModal",
  components: {
    BIconTrashFill,
    BIconPlus,
    BIconCalendar2Check,
    PeriodFilterModal,
  },
  props: {
    selectedAddOption: {
      type: String,
      default: "",
    },
    selectedEditOption: {
      type: String,
      default: "",
    },
    selectedRemoveOption: {
      type: String,
      default: "",
    },
    selectedOptionType: {
      type: String,
      default: "",
    },
    selectedAnimalsWeighings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      weighings: [],
      open: false,
      inputValue: { weight: "", date: "" },
      promptRemove: false,
      promptRemoveIndex: 0,
      addInput: false,
      openEdit: false,
      editIndex: 0,
    };
  },
  watch: {
    weighings() {
      this.$emit(
        "passData",
        this.weighings.map(({ date, ...weighing }) => {
          return {
            ...weighing,
            created_at: moment(date, "DD-MM-YYYY").toISOString(),
          };
        }),
      );
    },
  },
  created() {
    if (this.selectedOptionType !== "add") {
      this.weighings = this.selectedAnimalsWeighings.map(({ id, weight, created_at }) => ({
        id,
        weight,
        date: moment(created_at).format("DD-MM-YYYY"),
      }));
    }
  },
  methods: {
    handleChange(data) {
      this.inputValue.date = moment(data.startDate).format("DD-MM-YYYY");
      this.open = false;
    },
    handleChangeEdit(data) {
      const weighings = [...this.weighings];
      weighings[this.editIndex].date = moment(data.startDate).format("DD-MM-YYYY");
      this.weighings = weighings;
      this.openEdit = false;
      this.editIndex = 0;
    },

    updateWeighing(event, index, key) {
      const weighings = [...this.weighings];
      weighings[index][key] = event.target.value;
      this.weighings = weighings;
    },
    submitInput() {
      if (this.inputValue.weight && this.inputValue.date) {
        this.addInput = false;
        this.weighings.push({ id: "new", ...this.inputValue });
        this.inputValue = { weight: "", date: "" };
      }
    },
    handleRemove(index) {
      this.weighings.splice(index, 1);
      this.promptRemove = false;
    },
    handlePromptRemove(index) {
      this.promptRemove = true;
      this.promptRemoveIndex = index;
    },
    startEditDate(index) {
      this.editIndex = index;
      this.openEdit = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.weighings {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
}
</style>
