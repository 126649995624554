<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <router-link class="back" :to="{ path: '/groups' }">
              <div class="icon-chevron-back"></div>
              Groepen
            </router-link>
            <h1 class="page-title">
              {{ group.name }}
              <small v-if="animals"> ({{ animals.length }}) </small>
            </h1>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <!-- <period-filter :initialDateRange="period" v-on:periodchange="handlePeriodChange"></period-filter> -->
          <!-- <export-options :url="'default_lists/deaths/animals'"></export-options> -->
          <div class="d-inline-block">
            <b-dropdown
              id="dropdown-1"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              dropright
            >
              <template #button-content>
                <span class="download-link download"></span>
              </template>
              <b-dropdown-item @click="onPdfClick()">
                Download als
                <span class="ml-1 text-white text-bold">PDF</span>
              </b-dropdown-item>
              <b-dropdown-item @click="onExcelClick()">
                Download als
                <span class="ml-1 text-white text-bold">Excel</span>
              </b-dropdown-item>
            </b-dropdown>
            <span class="print-link download d-inline mr-2"></span>
          </div>
        </div>

        <div v-if="sortedAnimals">
          <div class="row">
            <div class="col-12">
              <Table :columns="columns" :data="sortedAnimals" :key-extractor="keyExtractor">
                <template #life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.data.id,
                    }"
                  >
                    {{ animal.data.attributes.life_number }}
                  </router-link>
                </template>
                <template #empty>Lege groep </template>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import pageLoader from "@/mixins/pageLoader";

export default {
  name: "Group",
  mixins: [pageLoader],
  data() {
    return {
      group: {},
      animals: [],
      error: "",
      query: "",
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "life_number", label: "(Werk)nummer", sortable: true },
        { key: "work_number", label: "Werknummer", sortable: true },
        { key: "human_animal_type", label: "Soort", sortable: true },
        { key: "date_of_birth", label: "Geboren", sortable: false },
        { key: "human_gender", label: "Geslacht", sortable: true },
      ],
      columns: {
        life_number: "(Werk)nummer",
        "data.attributes.work_number": "Werknummer",
        "data.attributes.animal_type": "Soort",
        "data.attributes.date_of_birth": "Geboren",
        "data.attributes.gender": "Geslacht",
        "data.attributes.number_of_children": "Nakomelingen",
      },
    };
  },
  computed: {
    filteredAnimals() {
      return this.animals.map((animal) => {
        return animal.data.attributes;
      });
    },
    sortedAnimals() {
      return [...this.animals].sort((a, b) => {
        return (
          new Date(b.data.attributes.group_updated_at) -
          new Date(a.data.attributes.group_updated_at)
        );
      });
    },
  },

  created() {
    this.$http.secured
      .get(`/groups/${this.$route.params.id}?include=animals`)
      .then((response) => {
        this.group = response.data.data.attributes;
        this.animals = response.data.data.attributes.animals;
        this.pageLoader_resolveData();
      })
      .catch((error) => this.setError(error, "Something went wrong"));
  },
  methods: {
    onPdfClick() {
      this.$http.secured
        .get(`/groups/${this.$route.params.id}.pdf`, {
          responseType: "blob",
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;

          fileLink.setAttribute("download", `Groep-${this.group.name}.pdf`);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    onExcelClick() {
      this.$http.secured
        .get(`/groups/${this.$route.params.id}.xlsx`, {
          responseType: "blob",
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Groep-${this.group.name}.xlsx`);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => this.setError(error, "Something went wrong"));
    },
    keyExtractor(animal) {
      return animal.data.attributes.id;
    },
  },
};
</script>
<style></style>
