var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('b-form-checkbox',{staticClass:"mt-2 mb-2",attrs:{"id":"included-checkbox","name":"included-checkbox","value":true,"unchecked-value":false},model:{value:(_vm.includes),callback:function ($$v) {_vm.includes=$$v},expression:"includes"}},[_vm._v(" Inclusief niet aanwezige dieren ")]),_c('div',{staticClass:"mt-2 mb-2 d-flex align-items-center"},[_c('search-input',{staticClass:"mr-auto",attrs:{"column":"female_animals_life_number"},on:{"search":_vm.onSearch}}),_c('period-filter',{attrs:{"period":_vm.period},on:{"change":_vm.handlePeriodChange}}),_c('export-options',{attrs:{"url":'breeding_programs',"period":_vm.period}})],1),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('Table',{attrs:{"sortable":"","columns":_vm.columns,"data":_vm.animals,"key-extractor":_vm.keyExtractor,"sort":_vm.sort},on:{"sort":_vm.onSort,"filterChange":_vm.onFilterChange},scopedSlots:_vm._u([{key:"animal_life_number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"animal_numbers",attrs:{"to":{
                  path: '/passport/' + item.animal.id,
                }}},[_c('span',[_vm._v(_vm._s(item.animal.animal_numbers.lifeNumber))]),_c('span',[_vm._v(_vm._s(item.animal.animal_numbers.workNumber))])])]}},{key:"human_animal_type",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.animal.human_animal_type)+" ")]}},{key:"male_animals",fn:function(ref){
                var item = ref.item;
return [_vm._l((item.breedingProgram.attributes.male_animals),function(maleAnimal){return [_c('span',{key:maleAnimal.id},[_vm._v(" "+_vm._s(maleAnimal.data.attributes.life_number)+" "),_c('br')])]})]}},{key:"human_method_type",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.breedingProgram.attributes.human_method_type)+" ")]}},{key:"empty",fn:function(){return [_vm._v("Er zijn nog geen resultaten")]},proxy:true}])})],1)])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title"},[_vm._v("Dekken")])])])}]

export { render, staticRenderFns }