<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div v-if="animalId">
        <!-- START INFO -->

        <div class="row justify-content-center my-4">
          <div class="col-4">
            <h6>Levensnummer</h6>
            <span class="font-xxl">{{ animal.life_number }}</span>
          </div>
          <div class="col-4">
            <h6>Diersoort</h6>
            <span class="font-xxl">{{ animal.human_animal_type }}</span>
          </div>
          <div class="col-4">
            <h6>Geboren</h6>
            <span class="font-xxl">
              {{ animal.date_of_birth ? animal.date_of_birth : "—" }}
            </span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-4">
            <h6>Werknummer</h6>
            <span class="font-xxl">{{ animal.life_number.slice(-5) }}</span>
          </div>
          <div class="col-4">
            <h6>Geslacht</h6>
            <span class="font-xxl">{{ animal.human_gender }}</span>
          </div>
          <div class="col-4">
            <h6>Leeftijd</h6>
            <span class="font-xxl">
              {{ animal.date_of_birth ? getYearsAndMonthsOld(animal.date_of_birth) : "—" }}
            </span>
          </div>
        </div>
        <!-- END INFO -->
        <!-- START CONTENT -->

        <div v-if="!error">
          <div v-for="(location, index) in locations" :key="location.id">
            <div class="item-container row justify-content-end my-4 ml-4">
              <div
                :class="`col-1 bar ${
                  index === 0 ? 'first' : index + 1 === locations.length ? 'last' : 'done'
                } 
            `"
              ></div>
              <div class="col-12">
                <card>
                  <div slot="header">
                    {{ location.attributes.present_from }} /
                    {{
                      !location.attributes.present_till
                        ? "huidig verblijf"
                        : location.attributes.present_till
                    }}
                  </div>
                  <div class="row">
                    <div class="d-flex flex-column col-4">
                      <span class="font-small-bold">UBN nummer</span>
                      <span>{{ location.attributes.ubn }}</span>
                    </div>
                    <div class="d-flex flex-column col-4">
                      <span class="font-small-bold">Adres</span>
                      <span>{{ location.attributes.street }}</span>
                      <span>{{ location.attributes.zip }}</span>
                      <span>{{ location.attributes.city }}</span>
                    </div>
                    <div class="d-flex flex-column col-4">
                      <span class="font-small-bold">Telefoonnummer</span>
                      <span>{{ location.attributes.telephone }}</span>
                    </div>
                  </div>
                </card>
              </div>
              <div v-if="index === 0" class="col-12 mt-4">
                <Table :columns="columns" :data="reversedGroups" :key-extractor="keyExtractor">
                  <template #name="{ item: group }">
                    <router-link :to="{ path: '/groups/' + group.id }">
                      {{ group.name }}
                    </router-link>
                  </template>
                  <template #present_till="{ item: group }">{{
                    formatDate(group.present_till) || "huidig verblijf"
                  }}</template>
                  <template #empty>Geen groepen</template>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="error-content">
          <p class="font-xl font-weight-bold">Er is een probleem opgetreden:</p>
          <p class="font-m">
            {{ error }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import pageLoader from "@/mixins/pageLoader";
import { axiosInstance } from "@/services/axios";

export default {
  name: "WhereAbouts",
  mixins: [pageLoader],

  data() {
    return {
      animalLoaded: false,
      animalId: "",
      locations: [],
      error: "",
      columns: {
        name: "Groep",
        present_from: "Aanvoerdatum",
        present_till: "Afvoerdatum",
      },
    };
  },
  computed: {
    ...mapState({
      animal: (state) => state.animals.item,
      groups: (state) => state.groups.items,
    }),
    animalGroup() {
      return this.groups.find((group) => group?.id === this.animal.group_id);
    },
    reversedGroups() {
      const tempGroups = this.animal.groups;
      return tempGroups.reverse();
    },
  },
  created() {
    this.animalId = this.$route.params.id;
    Promise.all([this.fetchAnimalById(this.animalId), this.fetchGroups()])
      .then(() => this.pageLoader_resolveData())
      .catch(() => {
        this.pageLoader_resolveData();
      });

    axiosInstance
      .get(`/animals/${this.animalId}/rvo_locations`)
      .then((res) => {
        this.locations = res.data.data;
      })
      .catch((error) => {
        if (error.response) {
          error.response.data.errors.forEach((e) => {
            this.error = e.detail;
          });
        }
      });
  },
  methods: {
    ...mapActions("animals", ["fetchAnimalById"]),
    ...mapActions("groups", ["fetchGroups"]),
    getYearsAndMonthsOld(date) {
      const dateArray = date?.split("-")?.reverse();

      if (!this.validAnimalDateArray(dateArray)) {
        return "";
      }

      const dateNow = moment();
      const birthDate = moment(date, "DD-MM-YYYY");

      const years = dateNow.diff(birthDate, "year");
      birthDate.add(years, "years");
      const months = dateNow.diff(birthDate, "months");
      const monthsText = months === 1 ? "maand" : "maanden";

      return `${years === 0 ? "" : `${years} jaar `}${months} ${monthsText} `;
    },
    validAnimalDateArray(dateArray) {
      if (dateArray && parseInt(dateArray[0]) && parseInt(dateArray[1]) && parseInt(dateArray[2])) {
        return true;
      }

      return false;
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date).format("DD-MM-YYYY");
    },
    keyExtractor(group) {
      return group.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.item-container {
  position: relative;

  .col-12 {
    padding-left: 1.5rem;
  }

  span {
    font-size: 0.875rem;
  }
}
.col-1 {
  all: initial;
}
.bar.first {
  &::after {
    content: "";
    background: url("../../assets/images/icons/passport-bar-unchecked.svg");
    background-color: white;
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
  }
}
.bar {
  width: 1px !important;
  background: gray;
  height: calc(100% + 1.5rem);
  position: absolute;
  left: 0;
  top: 15px;
  display: flex;
  justify-content: center;
}
.bar.last {
  height: 0px;

  &::after {
    content: "";
    background: url("../../assets/images/icons/passport-bar-checked.svg");
    background-color: white;
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
  }
}
.bar.done {
  height: calc(100% + 1.5rem);

  &::after {
    content: "";
    background: url("../../assets/images/icons/passport-bar-checked.svg");
    background-color: white;
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
  }
}

.error-content {
  padding-top: 2rem;
}
</style>
