var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_vm._m(0),(_vm.statistics)?_c('div',{staticClass:"row"},[_c('core-stat-card',{attrs:{"category":"Gem. melkgift ochtend in L","value":_vm._f("roundDecimal")(_vm.statistics.average_morning_milk_yield)}}),_c('core-stat-card',{attrs:{"category":"Gem. melkgift middag in L","value":_vm._f("roundDecimal")(_vm.statistics.average_afternoon_milk_yield)}}),_c('core-stat-card',{attrs:{"category":"Gem. melkgift avond in L","value":_vm._f("roundDecimal")(_vm.statistics.average_evening_milk_yield)}}),_c('core-stat-card',{attrs:{"category":"Gem. totale melkgift in L","value":_vm._f("roundDecimal")(_vm.statistics.average_milk_yield)}})],1):_vm._e(),_c('div',{staticClass:"mt-4 d-flex justify-content-end"}),(_vm.milkyields)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.milkyields,"key-extractor":_vm.keyExtractor},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var milkyield = ref.item;
return [_c('router-link',{attrs:{"to":{
                    path: '/groups/' + milkyield.id,
                  }}},[_c('span',[_vm._v(_vm._s(milkyield.attributes.name))])])]}},{key:"milk_yield",fn:function(ref){
                  var milkyield = ref.item;
return [_vm._v(_vm._s(_vm._f("roundDecimal")(milkyield.attributes.milk_yield)))]}},{key:"morning_milk_yield",fn:function(ref){
                  var milkyield = ref.item;
return [_vm._v(_vm._s(_vm._f("roundDecimal")(milkyield.attributes.morning_milk_yield)))]}},{key:"afternoon_milk_yield",fn:function(ref){
                  var milkyield = ref.item;
return [_vm._v(_vm._s(_vm._f("roundDecimal")(milkyield.attributes.afternoon_milk_yield)))]}},{key:"evening_milk_yield",fn:function(ref){
                  var milkyield = ref.item;
return [_vm._v(_vm._s(_vm._f("roundDecimal")(milkyield.attributes.evening_milk_yield)))]}},{key:"empty",fn:function(){return [_vm._v("Geen melkgiften per groep gevonden")]},proxy:true}],null,false,2502418818)}),(_vm.pagination.totalPages > 1)?_c('paginate',{attrs:{"page-count":_vm.pagination.totalPages,"click-handler":_vm.fetchPaginatedItems,"prev-text":'Vorige',"next-text":'Volgende',"container-class":'pagination justify-content-center pt-3',"page-class":'page-item',"page-link-class":'page-link',"prev-class":'page-item prev-item',"prev-link-class":'page-link prev-link-item',"next-class":'page-item next-item',"next-link-class":'page-link next-link-item',"break-view-class":'break-view',"break-view-link-class":'break-view-link',"first-last-button":false},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)])]):_c('div',[_c('AppSpinner')],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title"},[_vm._v("Melkgift per groep")])])])}]

export { render, staticRenderFns }