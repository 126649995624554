<template>
  <div>
    <div v-if="pageLoader_isDataLoaded" class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Geboorte Moederdieren</h1>
          </div>
        </div>
        <div v-if="statistics" class="row">
          <core-stat-card category="Worpen" :value="statistics.number_of_litter" />
          <core-stat-card
            category="Levend geboren"
            :value="statistics.number_of_lambs_born_alive"
          />
          <core-stat-card
            category="Gemiddelde worpgrootte"
            :value="statistics.average_litter_size | roundDecimal"
          />
          <core-stat-card category="Vrouwelijke lammeren" :value="statistics.number_female_lambs" />
          <core-stat-card category="Mannelijke lammeren" :value="statistics.number_male_lambs" />
          <core-stat-card
            category="Doodgeboren lammeren"
            :value="statistics.number_of_lambs_born_death"
          />
        </div>
        <div class="d-flex mt-4">
          <tabs :items="['Moederdieren', 'Lammeren']" :active-index="0" @change="onTabChange" />
        </div>
        <b-form-checkbox
          id="included-checkbox"
          v-model="includes"
          name="included-checkbox"
          :value="true"
          :unchecked-value="false"
          class="mt-2 mb-2"
        >
          Inclusief niet aanwezige dieren
        </b-form-checkbox>
        <div class="mt-2 mb-2 d-flex align-items-center">
          <search-input class="mr-auto" column="mother_life_number" @search="onSearch" />
          <period-filter :period="period" @change="handlePeriodChange" />
          <export-options
            :period="period"
            :url="'default_lists/propagations/mother_animals'"
          ></export-options>
        </div>

        <div v-if="animals">
          <div class="row">
            <div class="col-12">
              <Table
                sortable
                :columns="columns"
                :data="animals"
                :key-extractor="keyExtractor"
                :sort="sort"
                @sort="onSort"
                @filterChange="onFilterChange"
              >
                <template #mother_life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.mother_id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ animal.mother_numbers.lifeNumber }}</span>
                    <span>{{ animal.mother_numbers.workNumber }}</span>
                  </router-link>
                </template>
                <template #mother_animal_type="{ item: animal }">
                  {{ animal.mother_human_animal_type }}
                </template>
                <template #empty>Geen geboorte</template>
              </Table>
              <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <AppSpinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import pageLoader from "@/mixins/pageLoader";
import defaultListPage from "@/mixins/defaultListPage";
import PeriodFilter from "@/components/Lists/PeriodFilter";
import ExportOptions from "@/components/Lists/ExportOptions";
import Tabs from "@/components/Tabs";
import CoreStatCard from "@/components/Cards/CoreStatCard";
import SearchInput from "@/components/SearchInput";
import animalTypes from "@/enums/animalTypes";

export default {
  name: "MotherAnimals",
  components: {
    Tabs,
    PeriodFilter,
    ExportOptions,
    CoreStatCard,
    SearchInput,
  },
  mixins: [pageLoader, defaultListPage("propagations/mother_animals", "birth")],
  data() {
    return {
      columns: {
        mother_life_number: "(Werk)nummer",
        mother_animal_type: { title: "Soort", filter: { type: "enum", values: animalTypes } },
        total_offspring: "Nakomelingen",
        female_offspring: "Vrouwelijk",
        male_offspring: "Mannelijk",
        still_born_count: "Doodgeboren",
      },
    };
  },
  computed: {
    ...mapState({
      animals: (state) => state.default_lists.items,
      pagination: (state) => state.default_lists.pagination,
      period: (state) => state.default_lists.period,
    }),
  },
  beforeCreate() {
    // eslint-disable-next-line no-unused-vars
    const { page, startDate, endDate } = this.$route.query;
    if (!page || !startDate || !endDate) {
      this.$store.commit("default_lists/setPage", 1);

      const today = new Date();
      const defaultStartDate = new Date(today.getFullYear(), 0, 1);
      const defaultEndDate = new Date(today.getFullYear(), 11, 31);
      const startDate = moment(defaultStartDate).format("DD-MM-YYYY");
      const endDate = moment(defaultEndDate).format("DD-MM-YYYY");

      this.$store.commit("default_lists/setPeriod", { startDate, endDate });
    }
  },
  methods: {
    onTabChange(index) {
      switch (index) {
        case 0:
          this.$router.push({
            path: "/lists/mother-animals",
            params: this.$route.params,
          });
          break;
        case 1:
          this.$router.push({
            path: "/lists/birth",
            params: this.$route.params,
          });
          break;
        default:
          break;
      }
    },
    keyExtractor(animal) {
      return animal.id;
    },
  },
};
</script>
<style></style>
