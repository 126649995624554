<template>
  <div>
    <div class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="page-title">Melkgift per ophaalbeurt</h1>
          </div>
        </div>
        <!-- <div v-if="statistics" class="row"> -->
        <!-- <core-stat-card category="Totaal" :value="statistics.number_of_deaths" />
          <core-stat-card
            category="Jonger dan 7 dagen"
            :value="statistics.number_of_deaths_below_7_days"
          />
          <core-stat-card
            category="Jonger dan 4 maanden"
            :value="statistics.number_of_deaths_below_4_months"
          />
          <core-stat-card
            category="Vrouwelijke dieren"
            :value="statistics.number_of_female_deaths"
          />
          <core-stat-card category="Mannelijke dieren" :value="statistics.number_of_male_deaths" />
          <core-stat-card
            category="Meest voorkomende oorzaak"
            :value="statistics.most_occuring_death_reason"
          /> -->
        <!-- </div> -->
        <div class="mt-4 d-flex justify-content-end">
          <!-- <period-filter :period="period" @change="handlePeriodChange" /> -->
          <!-- <export-options :period="period" :url="'default_lists/deaths/animals'"></export-options> -->
        </div>
        <div v-if="milkyields">
          <div class="row">
            <div class="col-12">
              <Table :columns="columns" :data="milkyields" :key-extractor="keyExtractor">
                <!-- <template #life_number="{ item: animal }">
                  <router-link
                    :to="{
                      path: '/passport/' + animal.id,
                    }"
                    class="animal_numbers"
                  >
                    <span>{{ animal.animal_numbers.lifeNumber }}</span>
                    <span>{{ animal.animal_numbers.workNumber }}</span>
                  </router-link>
                </template> -->
                <template #empty>Geen sterfte</template>
              </Table>
              <!-- <paginate
                v-if="pagination.totalPages > 1"
                v-model="pagination.page"
                :page-count="pagination.totalPages"
                :click-handler="fetchPaginatedItems"
                :prev-text="'Vorige'"
                :next-text="'Volgende'"
                :container-class="'pagination justify-content-center pt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'page-item next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
                :first-last-button="false"
              ></paginate> -->
            </div>
          </div>
        </div>
        <div v-else>
          <AppSpinner />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import PeriodFilter from "@/components/Lists/PeriodFilter.vue";
// import ExportOptions from "@/components/Lists/ExportOptions.vue";
// import CoreStatCard from "@/components/Cards/CoreStatCard.vue";
// import { axiosInstance } from "@/services/axios";
// TODO: this component is on hold for now
export default {
  name: "MilkPerPickup",
  components: {
    // PeriodFilter,
    // ExportOptions,
    // CoreStatCard,
  },
  data() {
    return {
      milkyields: null,
      columns: {
        // "attributes.date": "Datum",
        // "attributes.amount": "Liters",
        // human_animal_type: "Soort",
        // human_gender: "Geslacht",
        // date_of_death: "Overleden",
        // reason_of_death: "Oorzaak",
      },
    };
  },
  // Liters Kilogrammen Ophaaldatum Chloroform Celgetal Geom. celgetal Vet Eiwit Lactose Ureum Zuurtegraad vet Vriespunt Myristinezuur C14:0 Palmitinezuur C16:0
  // created() {
  //   axiosInstance.get("/milkings").then(res => {
  //     console.log(res);
  //     this.milkyields = res.data.data.map(milkyield => milkyield);
  //   });
  // },

  methods: {
    keyExtractor(item) {
      return item.id;
    },
  },
};
</script>
<style></style>
